import styled from 'styled-components';

export const LoadingSt = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.content};
  display: flex;
  align-items: center;
  justify-content: center;

  h3{
    font-size: 26px;
    margin-right: 5px;
  }

  .loader {
    margin-bottom: -10px;
    width: 40px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side,#ffffffff 90%,#0000);
    background: 
      var(--_g) 0%   50%,
      var(--_g) 50%  50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    animation: l7 1s infinite linear;
  }

  @keyframes l7 {
      33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
      50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
      66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
  }
`;
