import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import HeaderDesktop from '../../components/Header';
import Center from '../../components/Center';
import * as styles from './style';
import ProjectsThumb from '../../components/ProjectsThumb';
import { db } from '../../service/firebaseConnection';

export default function Projects() {
  const [projects, setProjects] = useState([]);

  async function searchProject() {
    const postsRef = collection(db, 'projects');

    await getDocs(postsRef)
      .then((snapshot) => {
        const project = [];

        snapshot.forEach((doc) => {
          project.push({
            id: doc.id,
            perfil: doc.data().perfil,
            thumbnail: doc.data().thumbnail,
            name: doc.data().name,
            description: doc.data().description,
            tec: doc.data().tec,
            photos: doc.data().photos,
            linkO: doc.data().linkO,
          });
        });

        setProjects(project);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    searchProject();
  }, []);

  return (
    <article className="article_section">
      <HeaderDesktop />

      <Center>
        <styles.ProjectSt className="box_section">
          <h2 className="title_section">Projetos</h2>
        </styles.ProjectSt>

        {projects.map((project) => (
          <ProjectsThumb project={project} />
        ))}

      </Center>
    </article>
  );
}
