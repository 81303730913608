import React from 'react';
import { FooterSt } from './style';

export default function Footer() {
  return (
    <FooterSt>
      <span>Todos os direitos reservados Fabricio Cipriano &copy;</span>
    </FooterSt>
  );
}
