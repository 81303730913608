import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
// eslint-disable-next-line import/no-unresolved
import { Toaster, toast } from 'sonner';
import HeaderDesktop from '../../components/Header';
import Center from '../../components/Center';
import Social from '../../components/Social';
import perfil from '../../assets/perfil.jpeg';
import * as styles from './style';

export default function Contact() {
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [emailc, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [verif, setVerif] = useState(false);

  const form = useRef();

  async function sendForm(e) {
    e.preventDefault();
    setVerif(true);

    if (name === '' || tel === '' || emailc === '') {
      toast.warning('Preencha todos os campos!');
      setVerif(false);
      return;
    }

    await emailjs.sendForm('service_0gcu8cw', 'template_uojo90k', form.current, '2F-4jPpA6-k4vQO0B')
      .then(() => {
        toast.success('Mensagem enviada com sucesso!');
        setName('');
        setTel('');
        setEmail('');
        setMessage('');
        setVerif(false);
      }, () => {
        toast.error('Ocorreu um imprevisto! Tente novamente mais tarde');
        setVerif(false);
      });

    setVerif(false);
  }

  return (
    <article className="article_section">
      <Toaster richColors position="top-right" className="toast" />

      <HeaderDesktop />

      <Center>

        <styles.ContactSt className="box_section">

          <styles.ContactInfo>
            <h2 className="title_section">Entre em contato</h2>

            <styles.ContactInfoGroup>
              <div className="div_one" />
              <div className="div_two" />
              <img src={perfil} alt="perfil" />
            </styles.ContactInfoGroup>
          </styles.ContactInfo>

          <styles.ContactForm ref={form} onSubmit={sendForm}>
            <h2 className="title_section">Mande sua mensagem</h2>

            <styles.ContactFormInput>
              <span>Qual seu nome?</span>
              <input type="text" name="from_name" value={name} onChange={(e) => setName(e.target.value)} />
            </styles.ContactFormInput>

            <styles.ContactFormInput>
              <span>Qual seu telefone?</span>
              <input type="text" name="user_number" value={tel} onChange={(e) => setTel(e.target.tel)} />
            </styles.ContactFormInput>

            <styles.ContactFormInput>
              <span>Qual seu melhor email?</span>
              <input type="email" name="user_email" value={emailc} onChange={(e) => setEmail(e.target.email)} />
            </styles.ContactFormInput>

            <styles.ContactFormInput>
              <span>Deixe uma pergunta</span>
              <textarea rows="" cols="" name="message" value={message} onChange={(e) => setMessage(e.target.message)} />
            </styles.ContactFormInput>

            <button type="submit">{verif ? 'Enviando...' : 'Enviar'}</button>

          </styles.ContactForm>
        </styles.ContactSt>

        <styles.ContactSocial className="box_section">
          <Social />
        </styles.ContactSocial>
      </Center>
    </article>
  );
}
