// eslint-disable-next-line import/no-unresolved
import { initializeApp } from 'firebase/app';
// eslint-disable-next-line import/no-unresolved
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAbxkQkNaqcaiU9wKd7N7AiMiOBSIXqJGA',
  authDomain: 'fabricio-cipriano-portfolio.firebaseapp.com',
  projectId: 'fabricio-cipriano-portfolio',
  storageBucket: 'fabricio-cipriano-portfolio.appspot.com',
  messagingSenderId: '388088703541',
  appId: '1:388088703541:web:279ce188cf5ba2ec3adf7c',
  measurementId: 'G-14F55KFQF3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };
