import React from 'react';
import * as styles from './style';

export default function Loading() {
  return (
    <styles.LoadingSt>

      <h3>Carregando</h3>
      <div className="loader" />

    </styles.LoadingSt>
  );
}
