import React from 'react';
import { FaGithub } from 'react-icons/fa6';
import { RiInstagramFill } from 'react-icons/ri';
import { IoLogoWhatsapp } from 'react-icons/io';

import { FaLinkedin } from 'react-icons/fa';

import * as styles from './style';

export default function Social() {
  return (
    <styles.SocialSt>
      <a href="#1" target="blank" aria-label="link"><FaGithub /></a>
      <a href="#1" target="blank" aria-label="link"><FaLinkedin /></a>
      <a href="#1" target="blank" aria-label="link"><RiInstagramFill /></a>
      <a href="https://wa.me/5542999235625?text=Ol%C3%A1+Fabricio+Cipriano%2C+tudo+bem%3F" target="blank" aria-label="link"><IoLogoWhatsapp /></a>
    </styles.SocialSt>
  );
}
